.image {
    max-width: 100%; /* Ensure the image fits within the container */
    transition: max-width 0.3s ease; /* Add a smooth transition effect */
}

.fullScreen {
    object-fit: contain !important;
    max-width: 100vw; /* Set the image width to 100% of the viewport width */
    max-height: 100vh; /* Set the image height to 100% of the viewport height */
    position: fixed; /* Position the image fixed to the viewport */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; /* Center the image */
    z-index: 9999; /* Ensure the image is on top of other elements */
}
