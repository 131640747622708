input[type=checkbox]
{
    /* Double-sized Checkboxes */
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    padding: 10px;
}

input[type=checkbox]{
    accent-color: #6612d3;
}

.hover-link{
    transition: 0.35s all ease;
}
.hover-link:hover{
    box-shadow: 1px 1px 13px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 1px 1px 13px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 1px 1px 13px 0px rgba(0, 0, 0, 0.3);
}


.request-first{
    position: relative;
}

.request-first::after {
    position: absolute;
    right: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 3px;
    background-color: #eaeaea;
}

.address-last {
    position: relative;
}

.address-last::after{
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 3px;
    background-color: #eaeaea;
}


button.selected {
    position: relative;
}


button.selected::after{
    position: absolute;
    content: "";
    border-radius: 50%;
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    background: #6612d3;
    transform: translate(-50%, -50%);

}