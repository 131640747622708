.selected-address-card{
    background-color: rgba(102,18,211,0.17)!important;
}

#sider li p {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    padding-inline-start: 0.6rem;
    width: 100%;
}

#sider li{
    text-align: center;
}

.sidebar.active{
    width: 15% !important;
}


#sider .nav_list {
    margin-top: 32px;
    padding: 0 25.5px;
}
#sider li svg{
    height: 1.3rem !important;
}


#sider li svg path{
    stroke: #837f90 !important;
}

#sider li:not(#sider li.logoutItem):hover svg path, #sider li .active svg path{
    stroke: #fff !important;
}


@media screen and (max-width: 1600px){
    .sidebar.active{
        width: 20% !important;
    }
    .home_content {
        width: 80% !important;

    }
}

@media screen and (max-width: 1250px){
    .sidebar.active{
        width: 25% !important;
    }
    .home_content {
        width: 75% !important;

    }
}

@media screen and (max-width: 991px){
    .home_content {
        width: 100% !important;

    }
}

body .sidebar ul.dropmenu{
    padding-top: 0 !important;
}
.sidebar .dropmenu li, .dropmenu li a {
    padding: 0 !important;
    height: fit-content !important;
    line-height: normal;
}
.sidebar .dropmenu li{
    position: relative;
    margin-inline-start: 2rem;
    padding-inline-start: 1.2rem !important;
}

.sidebar  .dropmenu li a:hover, .dropmenu li:hover, .sidebar .dropmenu li a.active, .sidebar .dropmenu li:has(a.active)::before{
    background: transparent!important;
    color: #6612d3 !important;
}

body .sidebar .dropmenu li::before{
    position: absolute;
    content: '.';
    top: -17px;
    right: 0;
    left: auto;
    font-size: 2rem;
    font-weight: bolder;
}

body:not(.arbic) .sidebar .dropmenu li::before{
    position: absolute;
    content: '.';
    top: -17px;
    left: 0;
    right: auto;
    font-size: 2rem;
    font-weight: bolder;
}



